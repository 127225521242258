import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import { theme } from "../../../../utils/global-styles/theme";

import PlayVideoIcon from "../../../shared/PlayVideoIcon";
import VideoOverlay from "../../../../components/BecomeAFellow/child-components/VideoOverlay";

const BehindTheScenes = ({ heading, image, link, imageText }) => {
  const [videoOverlayActive, setVideoOverlayActive] = useState(false),
    [hover, setHover] = useState(false),
    handleVideoContainerClick = () => {
      setHover(false);
      videoOverlayActive
        ? setVideoOverlayActive(false)
        : setVideoOverlayActive(true);
    };

  useEffect(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop,
      scrollLeft = window.pageXOPffset || document.documentElement.scrollLeft;

    return (window.onscroll = videoOverlayActive
      ? () => window.scrollTo(scrollLeft, scrollTop)
      : function() {});
  }, [videoOverlayActive]);

  const Container = styled.section`
      margin-top: 120px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-top: 50px;
      }
    `,
    BehindTheScenesGrid = styled.div`
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      font-family: ${theme.fontFaces.headers};

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-rows: auto;
      }
    `,
    BehindTheScenesHeading = styled.h1`
      font-weight: bold;
      margin-bottom: 40px;

      z-index: 2;
      max-width: 880px;
      padding-left: 80px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-bottom: -14px;
        padding-left: 13px;
      }
    `,
    BehindTheScenesImage = styled(Img)`
      grid-area: 1 / 1 / span 1 / span 1;
      height: 100% / ${image.fluid.aspectRatio};
      width: 100%;
      z-index: -1;

      @media only screen and (max-width: ${theme.breakpoints.xlDesktopUp}) {
        height: 577px;
        width: 100%;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        height: 265px;
        width: 100%;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        height: 200px;
        width: 100%;
      }
    `,
    ImageContentContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      padding-left: 80px;
      padding-top: 288px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-left: 16px;
        padding-top: 45px;
      }
    `,
    ImageText = styled.h2`
      font-size: 53px;
      font-weight: bold;
      letter-spacing: -1.38px;
      line-height: 64px;
      z-index: 0;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 20px;
        letter-spacing: -0.52px;
        line-height: 24px;
      }
    `,
    WatchVideoTextContainer = styled.div`
      width: max-content;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-top: 30px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-top: 12px;
      }
    `,
    WatchVideoText = styled.h3`
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -1.04px;
      line-height: 48px;
      margin-left: 20px;
      z-index: 2;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 20px;
        letter-spacing: -0.52px;
        line-height: 24px;
      }
    `,
    HoverOverlay = styled.div`
      grid-area: 2 / 1 / span 2 / span 1;
      opacity: 0;
      transition: 0.2s ease-in-out all;
      width: 100%;
      z-index: 1;
      &.active {
        opacity: 0.25;
        cursor: pointer;
        transition: 0.2s ease-in-out all;
      }
    `,
    PlayIcon = styled(PlayVideoIcon)`
      z-index: 2;
    `;

  return (
    <Container>
      <BehindTheScenesHeading>{heading}</BehindTheScenesHeading>
      {videoOverlayActive && (
        <VideoOverlay
          show={videoOverlayActive}
          closeVideoOverlay={handleVideoContainerClick}
          videoUrl={link.url}
        />
      )}
      <BehindTheScenesGrid>
        <BehindTheScenesImage fluid={image.fluid} alt={image.description} />
        <HoverOverlay
          onClick={() => handleVideoContainerClick()}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={`primary-bg ${hover ? "active" : ""}`}
        />
        <ImageContentContainer
          onClick={() => handleVideoContainerClick()}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <ImageText className="white">{imageText.description}</ImageText>
          <WatchVideoTextContainer className={"main white"}>
            <PlayIcon
              height="35px"
              width="35px"
              mobileHeight="21px"
              mobileWidth="21px"
              fillColorClassNames={"white-fill"}
            />
            <WatchVideoText className="white">{link.linkText}</WatchVideoText>
          </WatchVideoTextContainer>
        </ImageContentContainer>
      </BehindTheScenesGrid>
    </Container>
  );
};

BehindTheScenes.defaultProps = {
  title: "Behind the Scenes",
  image: {
    fluid: {
      srcSet: `//images.ctfassets.net/wshh1v3ruvt9/2PsPRjCNF4tzrW43iBqiO2/17158f42dc58f705823e290610ef0df0/Screen_Shot_2020-01-30_at_3.55.45_PM.png?w=200&h=98&q=50&fm=webp 200w,
        //images.ctfassets.net/wshh1v3ruvt9/2PsPRjCNF4tzrW43iBqiO2/17158f42dc58f705823e290610ef0df0/Screen_Shot_2020-01-30_at_3.55.45_PM.png?w=400&h=197&q=50&fm=webp 400w,
        //images.ctfassets.net/wshh1v3ruvt9/2PsPRjCNF4tzrW43iBqiO2/17158f42dc58f705823e290610ef0df0/Screen_Shot_2020-01-30_at_3.55.45_PM.png?w=800&h=393&q=50&fm=webp 800w,
        //images.ctfassets.net/wshh1v3ruvt9/2PsPRjCNF4tzrW43iBqiO2/17158f42dc58f705823e290610ef0df0/Screen_Shot_2020-01-30_at_3.55.45_PM.png?w=934&h=459&q=50&fm=webp 934w`,
      aspectRatio: 2.0348583877995643,
    },
    description: "Woman standing in front of building",
  },
  link: {
    linkText: "Watch Now",
    url: `https://www.youtube.com/embed/wyquu-HXNGo`,
  },
  imageText: {
    description: "See the Acumen fellows in action",
  },
};

BehindTheScenes.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      srcSet: PropTypes.string,
      aspectRatio: PropTypes.number,
    }),
    description: PropTypes.string,
  }),
  link: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  imageText: PropTypes.shape({
    description: PropTypes.string,
  }),
};

export const query = graphql`
  fragment FellowshipBehindTheScenes on ContentfulPageLayout {
    contentModules {
      ... on ContentfulTitleDescription {
        title
        image {
          fluid {
            srcSet
            aspectRatio
          }
          description
        }
        link {
          linkText
          url
        }
        description: description {
          description
        }
      }
    }
  }
`;

export default BehindTheScenes;
