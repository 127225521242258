import React, { useState, useEffect } from "react";
import { navigate, graphql, Link } from "gatsby";
import Markdown from "markdown-to-jsx";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import { theme } from "../../../../utils/global-styles/theme";
import DropdownSelect from "../../../shared/DynamicForm/Dropdown/DropdownSelect";
import GlobeLogo from "../../../shared/GlobeIcon";
import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";

dayjs.extend(utc);

const FellowshipHero = ({
  heading,
  shortDescription,
  heroImage,
  regionDropdownValues,
  languageLinks,
  language,
  applicationStates,
  contentModules,
}) => {
  const dropDownFooter = regionDropdownValues[regionDropdownValues.length - 1],
    footerLink = {
      title: dropDownFooter.description.description,
      url: "/courseCatalog",
    };
  const placeholder = heading || "find your region",
    [mobileView, setMobileView] = useState(false),
    handleResize = () => {
      setMobileView(window.innerWidth < 801);
    },
    [open, setOpen] = useState(false),
    [hideLanguageLinks, setHideLanguageLinks] = useState(false),
    toggleOpenState = statePassed => {
      setOpen(!statePassed);
      if (mobileView && !statePassed) {
        setHideLanguageLinks(true);
      } else {
        setHideLanguageLinks(false);
      }
    },
    HeroWrapper = styled.section`
      display: grid;
      grid-template: 120px 1fr 120px / 0fr ${theme.breakpoints.maxContent} 1fr;
      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-template: 120px 1fr 120px / 80px 1fr 30%;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template: 120px 1fr 120px / 0px 1fr;
      }
    `,
    HeroImage = styled(Img)`
      grid-area: 1 / 1 / span 3 / span 3;
      height: 100% / ${heroImage.image.fluid.aspectRatio};
      width: 100%;
      z-index: 2;
    `,
    HeroData = styled.div`
      grid-area: 2 / 2 / span 2 / span 1;
      align-self: end;
      z-index: 3;
      padding-left: 80px;
      padding-right: 30%;
      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        padding: 0;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-area: 1 / 1 / span 3 / span 3;
        padding: 0 15px;
      }
    `,
    HeroLanguages = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: flex;
      z-index: 2;
      align-items: baseline;
      padding-left: 15px;
      align-self: center;
      padding-top: 20px;
      @media only screen and (min-width: ${theme.breakpoints
          .tabletLandscapeUp}) and (max-width: ${theme.breakpoints.iPadPro}) {
        padding-top: 20px;
      }
      @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
        padding-left: 80px;
      }
    `,
    HeroLanguage = styled.span`
      text-transform: uppercase;
      border-bottom: 3px solid;
      margin-right: 27px;
      font-family: ${theme.fontFaces.headers};
      font-weight: bold;
    `,
    HeroLanguageLink = styled(Link)`
      text-transform: uppercase;
      margin-right: 27px;
      font-family: ${theme.fontFaces.headers};
      font-weight: bold;
    `,
    HeroTitle = styled.h1`
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 40px;
        letter-spacing: -1.92px;
        line-height: 48px;
        margin-top: 100px;
      }
    `,
    HeroSubtitle = styled.p`
      font-size: 35px;
      font-family: ${theme.fontFaces.headers};
      font-weight: bold;
      letter-spacing: -0.9px;
      line-height: 43px;
      margin: 30px auto 50px auto;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
          font-size: 20px;
          letter-spacing: -0.52px;
          line-height: 24px;
        }
      }
    `,
    styledRegisterFinePrint = styled(Markdown)`
      color: ${theme.colors.main};
      font-size: 18px;
      letter-spacing: -0.39px;
      line-height: 26px;
      font-weight: 500;
      font-family: ${theme.fontFaces.help};
    `,
    formattedValues = regionDropdownValues.map(value => {
      const applicationsOpenDate = dayjs.utc(value.startDate),
        applicationsClosedDate = dayjs.utc(value.endDate),
        currentDate = dayjs(),
        hasApplicationOpened =
          value.startDate !== undefined
            ? currentDate.isSame(applicationsOpenDate, "minute") ||
              currentDate.isAfter(applicationsOpenDate, "minute")
            : null,
        hasApplicationEnded =
          currentDate.isSame(applicationsClosedDate, "minute") ||
          currentDate.isAfter(applicationsClosedDate, "minute");
      let message = "";
      if (value.startDate) {
        if (hasApplicationOpened && !hasApplicationEnded) {
          message = applicationStates[0];
        } else if (
          applicationsOpenDate &&
          !hasApplicationEnded &&
          applicationsOpenDate.format("MMM D") !== "Invalid date"
        ) {
          message = `${applicationStates[1]} ${applicationsOpenDate.format(
            "MMM D, HH:mm"
          )}`;
        }
        if (hasApplicationEnded) {
          message = applicationStates[2];
        }
      }
      return {
        name: value.linkText,
        key:
          value.linkText && value.linkText.replace(/\s+/g, "-").toLowerCase(),
        displayName: value.linkText,
        message,
        url: value.url,
        active: hasApplicationOpened && !hasApplicationEnded,
      };
    });
  const ofType = type => contentModules.filter(x => x.__typename === type),
    fellowshipLanderDropdownHeading = ofType("ContentfulCarouselContent")[0]
      ?.heading;

  // Sets up window resize handler
  useEffect(() => {
    // If executed server-side (no window object) it will return false(exit) out of the useEffect() hook
    if (typeof window !== "object") return false;
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [open]);

  return (
    <HeroWrapper className="secondary-bg">
      <HeroImage fluid={heroImage.image.fluid} />
      {!hideLanguageLinks && (
        <HeroLanguages>
          <HeroLanguage className="primary primary-border">
            {language}
          </HeroLanguage>

          {languageLinks.map((link, i) => (
            <HeroLanguageLink
              className="jungle-green jungle-green-hover"
              to={`${link.url}/`}
              key={i}
            >
              {link.linkText}
            </HeroLanguageLink>
          ))}
        </HeroLanguages>
      )}
      <HeroData>
        <HeroTitle className="codedH1 white">
          {heading || "Fellowship"}
        </HeroTitle>
        <HeroSubtitle className="white">
          {shortDescription || "lorem ipsum"}
        </HeroSubtitle>
        <DropdownSelect
          footerLink={footerLink}
          placeholder={{
            title: placeholder,
            value: null,
          }}
          fellowshipLanderDropdownHeading={fellowshipLanderDropdownHeading}
          toggleOpenState={toggleOpenState}
          open={open}
          values={formattedValues}
          fullscreenOnMobile={true}
          isMobile={mobileView}
          handleChange={e => {
            return e.key ? navigate(`${e.url}/`) : null;
          }}
          placeholderIcon={
            <GlobeLogo
              width="42px"
              height="42px"
              classNames="path-primary-fill"
            />
          }
          styles={{
            wrapperStyles: `
            width: 100%;
            &:focus {
              >div {
                border-style: solid;
                border-width: 2px;
              }
            }
            `,
            placeholderStyles: `
              font-size: 35px;
              font-weight: bold;
              letter-spacing: -0.9px;
              line-height: 60px;
              font-family: ${theme.fontFaces.headers};
              @media only screen and (max-width: ${theme.breakpoints.helper800}) {
                font-size: 22px;
                letter-spacing: -0.57px;
                line-height: 43px;
              }
            `,
            placeholderWrapperStyles: `
              padding: 12px 24px;
              display: flex;
              justify-content: space-between;
            `,
            listFooterStyles: `
             
              border-bottom: 1px solid #eeeeee;
              background-color: ${theme.colors.white};
              font-family: ${theme.fontFaces.headers};
              padding: 6px 24px;
              align-items: center;
              font-size: 35px;
              font-weight: bold;
              letter-spacing: -0.9px;
              line-height: 60px;
              @media only screen and (max-width: ${theme.breakpoints.helper800}) {
                font-size: 22px;
                font-weight: bold;
                letter-spacing: -0.57px;
                line-height: 43px;
              }
            `,
            itemStyles: `
              border-top: 1px solid #eeeeee;
              border-bottom: 1px solid #eeeeee;
              background-color: ${theme.colors.white};
              font-family: ${theme.fontFaces.headers};
              padding: 6px 24px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 35px;
              font-weight: bold;
              letter-spacing: -0.9px;
              line-height: 60px;
              @media only screen and (max-width: ${theme.breakpoints.helper800}) {
                font-size: 22px;
                font-weight: bold;
                letter-spacing: -0.57px;
                line-height: 43px;
              }
            `,
            itemMessageStyles: `
              font-family: ${theme.fontFaces.headers};
              font-weight: 700;
              font-size: 17px;
              line-height: 24px;
              @media only screen and (max-width: ${theme.breakpoints.helper800}) {
                font-size: 12px;
              }
            `,
            selectStyles: `
              box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.1);
              margin-bottom: 100px;
            `,
          }}
        />
      </HeroData>
    </HeroWrapper>
  );
};

export const query = graphql`
  fragment FellowshipLanderHero on ContentfulPageLayout {
    heading
    shortDescription
    heroImage {
      image {
        fluid {
          aspectRatio
          srcSet
        }
        description
      }
    }
    contentModules {
      ... on ContentfulLink {
        linkText
        url
        startDate
        endDate
      }
      ... on ContentfulCarouselContent {
        heading
        title
        carouselItems {
          ... on ContentfulLink {
            linkText
            url
            startDate
            endDate
          }
          ... on ContentfulTitleDescription {
            description {
              description
            }
          }
        }
      }
    }
  }
`;

export default FellowshipHero;
