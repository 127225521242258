import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Markdown from "markdown-to-jsx";
import { Link } from "gatsby";
import moment from "moment";

import { theme } from "../../../../../utils/global-styles/theme";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 40px 25px 40px;
    background-color: ${theme.colors.background};
  `,
  RegionTextContainer = styled.div`
    display: flex;
    align-items: center;
  `,
  RegionText = styled.h3`
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.22px;
    line-height: 30px;
    color: ${theme.colors.black};
    font-family: ${theme.fontFaces.headers};

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 20px;
      letter-spacing: -0.18px;
      line-height: 24px;
    }
  `,
  ApplicationStatusContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  `,
  ApplicationStatusText = styled.h3`
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 12px;
      line-height: 24px;
    }
  `,
  DescriptionContainer = styled.div`
    overflow-y: hidden;
  `,
  DescriptionText = styled.p`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.18px;
    line-height: 24px;
    margin-top: 22px;
  `,
  LearnMoreButton = styled(Link)`
    height: 36px;
    width: 180px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 50px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin-bottom: 30px;
    }
  `;

const RegionalDialog = ({
  className,
  region,
  regionDescription,
  startDate,
  endDate,
  linkText,
  linkUrl,
}) => {
  const currentDate = moment(new Date())
      .format("YYYY-MM-DDTHH:mmZ"),
    applicationStartDate = moment(startDate),
    applicationEndDate = moment(endDate),
    areApplicationsOpen =
      Number(applicationStartDate.diff(currentDate, "days")) <= 0 &&
      Number(applicationEndDate.diff(currentDate, "days")) >= 0;

  return (
    <Container className={className || ""}>
      <ApplicationStatusContainer>
        <ApplicationStatusText className="primary">
          {areApplicationsOpen ? "APPLICATIONS OPEN" : ""}
        </ApplicationStatusText>
      </ApplicationStatusContainer>
      <RegionTextContainer>
        <RegionText className="primary">{region}</RegionText>
      </RegionTextContainer>

      <DescriptionContainer>
        {region.toLowerCase().trim() === "west africa" ||
        region.toLowerCase().trim() === "east africa" ? (
          <Markdown
            options={{
              overrides: {
                strong: {
                  props: {
                    className: "fellowship-regional-description-strong",
                  },
                },
              },
            }}
          >
            {regionDescription}
          </Markdown>
        ) : (
          <DescriptionText>{regionDescription}</DescriptionText>
        )}
        <LearnMoreButton
          className="black black-button"
          to={`${linkUrl}/`}
        >
          {linkText}
        </LearnMoreButton>
      </DescriptionContainer>
    </Container>
  );
};

RegionalDialog.propTypes = {
  className: PropTypes.string,
  region: PropTypes.string.isRequired,
  regionDescription: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

export default RegionalDialog;
