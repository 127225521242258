import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";

const LargeLogo = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "515.655"}
    height={height || "460.696"}
    viewBox="0 0 515.655 460.696"
    className={`${className} large-logo`}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_523"
          data-name="Rectangle 523"
          width="515.655"
          height="460.696"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_455" data-name="Group 455" transform="translate(-246 -732)">
      <g id="Group_454" data-name="Group 454" transform="translate(246 732)">
        <g id="Group_453" data-name="Group 453" clip-path="url(#clip-path)">
          <path
            id="Path_499"
            data-name="Path 499"
            d="M163.949,0C138.844,0,108.36.22,107.06.56V80.31h59.893q-56.527,150.662-112.8,300.6h52.106q56.373-150.236,112.793-300.6H159.167V.56c.811-.21,12.927-.376,28.015-.471C181.163.028,172.9,0,163.949,0M52.464,380.915H.36c-.576,3.882-.407,77.46.128,79.451,1.615.23,37.16.33,78.015.33,8.856,0,17.961,0,27.024-.015-29.361-.033-51.664-.133-52.933-.315-.537-1.991-.706-75.569-.131-79.451"
            transform="translate(0.001)"
            fill="#0093bb"
          />
          <path
            id="Path_500"
            data-name="Path 500"
            d="M151.768,0c-9.478,0-19.724.031-28.874.09,4.867.049,8.263.12,9.363.215.246.663.581,1.4.8,2.178Q152.031,68.517,171,134.551a9.456,9.456,0,0,1-.113,5.947q-16.34,48.628-32.559,97.294c-.345,1.036-.64,2.088-1.044,3.416H201.7c7.572,26.413,15.105,52.7,22.733,79.287h52.1c-7.625-26.587-15.159-52.874-22.731-79.287h-64.41c.407-1.328.7-2.38,1.039-3.416Q206.7,189.133,223,140.5a9.456,9.456,0,0,0,.107-5.947q-19-66.023-37.94-132.069c-.22-.775-.558-1.515-.8-2.178C181.9.092,167.951,0,151.768,0m11.072,320.495H110.734c-6.709,20.085-13.375,40.054-20.149,60.333H138.1c.624,2.193.86,74.486.325,79.359-.967.266-48.468.448-97.186.494,7.938.013,16.387.015,25.082.015,56.464,0,123.064-.192,124.205-.509.54-4.872.3-77.166-.32-79.359H142.692c6.773-20.279,13.442-40.249,20.149-60.333"
            transform="translate(64.288 0)"
            fill="#002f71"
          />
          <path
            id="Path_501"
            data-name="Path 501"
            d="M155.438.035c-15.087.095-27.206.258-28.017.471V80.258h59.9q-56.531,150.658-112.8,300.6h-53.8c-.573,3.882-.4,77.46.131,79.451,1.269.182,23.572.279,52.933.317,48.718-.051,96.219-.233,97.184-.5.537-4.87.3-77.166-.322-79.359H123.13c6.773-20.279,13.439-40.251,20.149-60.333h113.7c-7.625-26.589-15.159-52.874-22.733-79.284H169.835c.4-1.331.7-2.382,1.041-3.419q16.255-48.658,32.559-97.3a9.441,9.441,0,0,0,.113-5.942Q184.54,68.472,165.6,2.428c-.22-.775-.555-1.517-.8-2.178-1.1-.095-4.5-.166-9.363-.215"
            transform="translate(31.744 0.055)"
            fill="#006f84"
          />
        </g>
      </g>
    </g>
  </svg>
);

LargeLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default LargeLogo;
