import React from "react";
import PropTypes from "prop-types";
import Styled from "@emotion/styled";
import { graphql } from "gatsby";
import { theme } from "../../../utils/global-styles/theme";
import { css } from "@emotion/core";
import FellowBackgroundImage from "../../BecomeAFellow/child-components/FellowBackgroundImage";
import AscendingCascadingCards from "../../AscendingCascadingCards/AscendingCascadingCards";

const FellowshipSelection = ({ heading, description, cards }) => {
  const Container = Styled.section`
    margin-top: 0px;
    margin-bottom: 120px;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  `,
    Wrapper = Styled.div`
    display: grid;
    grid-template: 200px auto / 80px 60% 1fr;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
    }
  `,
    Title = Styled.h1`
      font-weight: bold;
      margin-bottom: 40px;

      z-index: 2;
      max-width: 880px;
      padding-left: 80px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0 40px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 0 40px;
      }
    `,
    BackgroundImageStyles = css`
      display: grid;
      grid-template: auto 60px auto 200px / 100px 45% 1fr;
      grid-area: 1 / 1 / span 3 / span 4;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template: auto / 24px 60% 1fr;
      }
      z-index: -1;
    `,
    StyledDescription = Styled.h2`
      font-size: 35px;
      font-weight: 500;
      letter-spacing: -0.9px;
      line-height: 43px;
      text-shadow: 0px 0px 4px rgba(0,0,0,0.5);
      margin-top: 75px;
      grid-area: 2 / 2 / span 2 / span 1;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 22px;
        letter-spacing: -0.57px;
        line-height: 24px;
        margin: 20px auto;
      }
    `,
    Description = Styled.h5`
      grid-area: 1 / 2 / 2 / span 4;
      min-height: 300px;
      max-width: 50%;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 40px;
        max-width: 100%;
      }
      
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 40px;
        max-width: 100%;
      }
    `;

  return (
    <Container>
      <Title>{heading}</Title>
      <Wrapper>
        <Description>{description}</Description>
        <AscendingCascadingCards cards={cards} />
      </Wrapper>
    </Container>
  );
};

FellowshipSelection.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      benefit: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export const query = graphql`
  fragment FellowshipSelection on ContentfulPageLayout {
    contentModules {
      ... on ContentfulBenefitsCard {
        title
        heading
        descriptionText: description
        benefit1
        description1
        benefit2
        description2
        benefit3
        description3
        __typename
      }
    }
  }
`;

export default FellowshipSelection;
