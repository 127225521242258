import React from "react";
import PropTypes from "prop-types";
import Styled from "@emotion/styled";
import { css } from "@emotion/core";
import Markdown from "markdown-to-jsx";
import { graphql } from "gatsby";
import { theme } from "../../../../utils/global-styles/theme";
import LargeLogo from "../../../shared/LargeLogo";

const FellowshipExperience = ({ title, markdown }) => {
  const Wrapper = Styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 120px;

    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      display: flex;
      flex-direction: column;
      padding: 0 40px;
      margin: 50px auto;
    }
    `,
    Title = Styled.h1`
      grid-area: 2 / 1 / span 1 / span 2;
      margin-left: 342px;
      margin-bottom: 40px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin: 0;
      }
     `,
    StyledMarkdown = Styled(Markdown)`
      grid-area: 3 / 1 / 3 / span 2;
    `;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <StyledMarkdown
        options={{
          overrides: {
            h2: {
              props: {
                className: "fellowship-experience-headers",
              },
            },
            h3: {
              props: {
                className: "fellowship-experience-heading",
              },
            },
            p: {
              props: {
                className: "fellowship-experience-paragraph",
              },
            },
            ul: {
              props: {
                className: "fellowship-experience-list",
              },
            },
            li: {
              props: {
                className: "fellowship-experience-list-item",
              },
            },
          },
        }}
      >
        {markdown}
      </StyledMarkdown>
    </Wrapper>
  );
};

FellowshipExperience.propTypes = {
  title: PropTypes.string.isRequired,
  markdown: PropTypes.string.isRequired,
};

export const query = graphql`
  fragment FellowshipExperience on ContentfulPageLayout {
    contentModules {
      ... on ContentfulTitleDescription {
        name
        image {
          fluid {
            srcSet
            aspectRatio
          }
          description
        }
        link {
          linkText
          url
        }
        description: description {
          description
        }
      }
    }
  }
`;

export default FellowshipExperience;
