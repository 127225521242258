import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import { css } from "@emotion/core";

import { theme } from "../../../utils/global-styles/theme";

const FellowBackgroundImage = ({ child, styles }) => (
  <StaticQuery
    query={graphql`
      {
        allBgImgs: allFile(
          filter: { absolutePath: { regex: "/become-a-fellow-bg/" } }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                id
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const currentBgImage = data.allBgImgs.edges.filter(img =>
        img.node.relativePath.includes(theme.colors.themeName)
      );

      const imageData = currentBgImage[0].node.childImageSharp.fluid;
      
      return (
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          css={css`
            ${styles}
          `}
        >
          {child || null}
        </BackgroundImage>
      );
    }}
  ></StaticQuery>
);

FellowBackgroundImage.propTypes = {
  child: PropTypes.element,
  styles: PropTypes.object,
};

export default FellowBackgroundImage;
