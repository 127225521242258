import React from "react";
import styled from "@emotion/styled";
import get from "lodash/get";
import { Link, graphql } from "gatsby";

import { theme } from "../../../../utils/global-styles/theme";

import RegionAccordion from "./child-components/RegionAccordion";

const AroundTheGlobeAccordions = ({
  className,
  heading,
  cta,
  carouselItems,
}) => {
  const MainAccordionsGrid = styled.section`
      display: grid;
      grid-template-rows: 120px 1fr 120px;
      grid-template-columns: 120px 1fr 284px;
      font-family: ${theme.fontFaces.headers};

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-template-columns: 40px 1fr 174px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-rows: 50px 1fr 0px;
        grid-template-columns: 0px 1fr 0px;
      }
    `,
    AccordionsContainer = styled.div`
      grid-area: 2 / 2 / span 1 / span 1;
    `,
    AccordionsHeading = styled.h1`
      max-width: 880px;
      font-size: 130px;
      font-weight: bold;
      letter-spacing: -6.25px;
      line-height: 132px;
      margin-bottom: 50px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 24px;
        letter-spacing: -0.52px;
        line-height: 24px;
        margin-bottom: 15px;
        margin-left: 13px;
      }
    `,
    CtaContainer = styled.div`
      height: auto;
      margin-top: 50px;
      padding-left: 206px;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        padding-left: 160px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        display: none;
      }
    `,
    CtaText = styled.h2`
      font-size: 35px;
      font-weight: bold;
      letter-spacing: -0.91px;
      line-height: 35px;
      margin-bottom: 22px;
    `,
    CtaButton = styled(Link)`
      height: 36px;
      width: 166px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid;
    `;

  return (
    <MainAccordionsGrid className={className || ""}>
      <AccordionsContainer>
        <AccordionsHeading>{heading || "Around the Globe"}</AccordionsHeading>
        {carouselItems
          .slice(1)
          .sort((a, b) => {
            var titleA = a.title.toLowerCase().trim();
            var titleB = b.title.toLowerCase().trim();
            if (titleA === titleB) return 0;
            return titleA < titleB ? -1 : 1;
          })
          .map((region, index) => {
            return (
              <RegionAccordion
                key={index}
                index={index}
                region={get(region, "title", "Region")}
                regionDescription={get(
                  region,
                  "description.description",
                  "Region"
                )}
                linkText={get(region, "link.linkTextAlt", "Learn More")}
                linkUrl={get(region, "link.url", "/fellowship")}
                startDate={get(
                  region,
                  "link.startDate",
                  "2030-06-30T00:00-07:00"
                )}
                endDate={get(region, "link.endDate", "2030-06-30T00:00-07:00")}
              />
            );
          })}
        <CtaContainer>
          <CtaText>
            {get(
              carouselItems,
              "[0].title",
              "Want to start an Acumen Fellowship in your country?"
            )}
          </CtaText>
          <CtaButton
            className="primary primary-border secondary-border-hover secondary-hover"
            to={`${get(cta, "url", "/contact")}/`}
          >
            {get(cta, "linkText", "Get in Touch")}
          </CtaButton>
        </CtaContainer>
      </AccordionsContainer>
    </MainAccordionsGrid>
  );
};

export const query = graphql`
  fragment FellowshipAroundTheGlobe on ContentfulPageLayout {
    contentModules {
      ... on ContentfulCarouselContent {
        title
        heading
        cta {
          linkText
          url
        }
        carouselItems {
          ... on ContentfulTitleDescription {
            title
            link {
              linkTextAlt
              url
              startDate
              endDate
            }
            description {
              description
            }
          }
        }
      }
    }
  }
`;

export default AroundTheGlobeAccordions;
