import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { theme } from "../utils/global-styles/theme";
import Layout from "../components/Layout/Layout";
import PageBackground from "../components/Layout/PageBackground";
import FellowshipHero from "../components/Fellowship/child-components/Hero/FellowshipHero";
import BehindTheScenes from "../components/Fellowship/child-components/FellowshipBehindTheScenes/BehindTheScenes";
import FellowshipExperience from "../components/Fellowship/child-components/Experience/FellowshipExperience";
import AroundTheGlobe from "../components/Fellowship/child-components/FellowshipAroundTheGlobe/AroundTheGlobe";
import AroundTheGlobeAccordion from "../components/Fellowship/child-components/FellowshipAroundTheGlobe/AroundTheGlobeAccordion";
import FellowshipTestmonials from "../components/Fellowship/child-components/FellowshipLanderTestimonials/FellowshipTestimonials";
import FeaturedFellows from "../components/Fellowship/child-components/FellowshipFeaturedFellows/FeaturedFellows";

import VideoSlider from "../components/shared/VideoSlider";
import FellowshipSelection from "../components/Fellowship/child-components/FellowshipSelection";
import FeaturedFoundryMembers from "../components/Foundry/child-components/FeaturedFoundryMembers";

const FellowshipPage = ({
  data,
  location: { pathname },
  pageContext: { language, locale, marketingModal },
}) => {
  const page = data.pages.nodes[0];
  const {
    heading,
    shortDescription,
    heroImage,
    contentModules,
    additionalTextsList: applicationStates,
  } = page;
  const ofType = type => contentModules.filter(x => x.__typename === type);
  const behindTheScenesData = ofType("ContentfulTitleDescription").find(obj =>
      /Fellowship Video/gi.test(obj.name)
    ),
    languageLinks = ofType("ContentfulLink"),
    regionOptions = ofType("ContentfulCarouselContent").find(obj =>
      /Dropdown Menu/gi.test(obj.title)
    );
  const heroData = {
      heading,
      shortDescription,
      heroImage,
      regionDropdownValues: regionOptions.carouselItems,
      language,
      languageLinks,
      applicationStates,
      contentModules,
    },
    fellowshipExperienceData = ofType("ContentfulTitleDescription").find(obj =>
      /Fellowship Description/gi.test(obj.name)
    ),
    aroundTheGlobeData = ofType("ContentfulCarouselContent").find(obj =>
      /Fellowship Map/gi.test(obj.title)
    ),
    testimonialSliderData = ofType("ContentfulCarouselContent").find(obj =>
      /Testimonials/gi.test(obj.title)
    ),
    selectionModule = ofType("ContentfulBenefitsCard").find(obj =>
      /Fellowship Selection Criteria/gi.test(obj.title)
    ),
    selectionData = selectionModule
      ? {
          heading: selectionModule.heading,
          description: selectionModule.descriptionText,
          cards: [1, 2, 3].map(i => ({
            description: selectionModule["description" + i],
            benefit: selectionModule["benefit" + i],
          })),
        }
      : null,
    influencerData = ofType("ContentfulCarouselContent").find(obj =>
      /Videos/gi.test(obj.title)
    ),
    featuredFellowsHeading =
      ofType("ContentfulTitleDescription").find(obj =>
        /Featured Fellows Heading/gi.test(obj.name)
      ) || {},
    featuredFellows = ofType("ContentfulNameImageDetails"),
    featuredFellowsData = {
      heading: featuredFellowsHeading.title,
      cta: featuredFellowsHeading.link,
      fellows: featuredFellows,
    },
    InfluencerTitleStyles = `
    width: 50%;
    position: relative;
    margin-left: 120px;
    margin-bottom: -60px;
    z-index: 3;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      width: 70%;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -1.92px;
      line-height: 40px;
      margin-left: 50px;
      margin-bottom: -15px;
    }`,
    InfluencerWrapperStyles = `
      margin-top: 120px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-top: 50px;
      }
    `,
    InfluencerSliderStyles = `
      margin-bottom: 0px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        margin-bottom: 0;
        h1 {
          margin: 0 15%;
        }
        @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        h1 {
          margin: 0 8%;
        }
      }
      }
    `;
  return (
    <Layout
      currentPath={pathname}
      enforceWidth={false}
      locale={locale}
      marketingModal={marketingModal}
    >
      <FellowshipHero {...heroData} />
      <PageBackground>
        <BehindTheScenes
          heading={behindTheScenesData.title || undefined}
          image={behindTheScenesData.image || undefined}
          link={behindTheScenesData.link || undefined}
          imageText={behindTheScenesData.description || undefined}
        />
        <AroundTheGlobe
          {...aroundTheGlobeData}
          css={{
            [`@media only screen and (max-width: ${theme.breakpoints.helper800})`]: {
              display: "none",
            },
          }}
        />
        <AroundTheGlobeAccordion
          {...aroundTheGlobeData}
          css={{
            display: "none",
            [`@media only screen and (max-width: ${theme.breakpoints.helper800})`]: {
              display: "grid",
            },
          }}
        />
        <FellowshipTestmonials
          bgImage={testimonialSliderData.backgroundImage}
          testimonials={testimonialSliderData.carouselItems}
        />
        <FellowshipExperience
          title={get(fellowshipExperienceData, "title", "")}
          markdown={get(
            fellowshipExperienceData,
            "description.description",
            ""
          )}
        />
        {selectionData ? (
          <FellowshipSelection
            heading={get(selectionData, "heading", "")}
            description={get(selectionData, "description", "")}
            cards={selectionData.cards}
          />
        ) : null}
        <FeaturedFoundryMembers
          header={get(featuredFellowsData, "heading", "Featured Fellows")}
          members={get(featuredFellowsData, "fellows", [])}
          link={get(featuredFellowsData, "cta", {
            linkText: "Meet the Fellows",
            url: "/meet-the-fellows",
          })}
        />
      </PageBackground>
      <VideoSlider
        title={get(influencerData, "heading", "Influencers")}
        videoSlides={get(influencerData, "carouselItems", []).map(x => {
          return { ...x, description: x.imageDescription };
        })}
        titleStyles={InfluencerTitleStyles}
        wrapperStyles={InfluencerWrapperStyles}
        sliderStyles={InfluencerSliderStyles}
      />
    </Layout>
  );
};

// templates should export a query for gatsby to execute
export const query = graphql`
  query($queryIdentifier: String!, $locale: String!) {
    pages: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: $queryIdentifier }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        ...FellowshipLanderHero
        ...FellowshipAroundTheGlobe
        ...FellowshipBehindTheScenes
        ...FellowshipExperience
        ...FellowshipFeaturedFellows
        ...FellowshipTestimonials
        ...FellowshipSelection
        additionalTextsList
        contentModules {
          ... on ContentfulCarouselContent {
            title
            heading
            carouselItems {
              ... on ContentfulImage {
                image {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
                imageCaption
                imageDescription: description
                link {
                  linkText
                  url
                }
                __typename
              }
            }
            __typename
          }
        }
      }
    }
  }
`;

export default FellowshipPage;
