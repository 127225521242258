import React from "react";
import styled from "@emotion/styled";
import get from "lodash/get";
import { Link, graphql } from "gatsby";

import { theme } from "../../../../utils/global-styles/theme";

import Globe from "./Globe";
import RegionDialog from "./child-components/RegionDialog";

const DialogContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto;
    grid-template-areas:
      "heading"
      "."
      "cta";

    padding-left: 342px;
    @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
      padding-left: 120px;
    }
  `,
  Heading = styled.h1`
    margin-bottom: 50px;
    grid-area: heading;
    z-index: 0;
  `,
  CtaContainer = styled.div`
    z-index: 2;
    height: auto;
    margin-top: 50px;
    grid-area: cta;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      display: none;
    }
  `,
  CtaText = styled.h2`
    font-size: 35px;
    font-weight: bold;
    letter-spacing: -0.91px;
    line-height: 35px;
    margin-bottom: 22px;
  `,
  CtaButton = styled(Link)`
    z-index: 2;
  `;

const AroundTheGlobe = ({ className, heading, cta, carouselItems }) => {
  const regions = carouselItems.slice(1).map((region, index) => {
    const dialog = (
        <RegionDialog
          key={index}
          region={get(region, "title", "Region")}
          regionDescription={get(region, "description.description", "Region")}
          linkText={get(region, "link.linkTextAlt", "Learn More")}
          linkUrl={get(region, "link.url", "/fellowship")}
          startDate={get(region, "link.startDate", "2030-06-30T00:00-07:00")}
          endDate={get(region, "link.endDate", "2030-06-30T00:00-07:00")}
        />
      ),
      label = get(region, "title", "Region");
    return {
      label,
      dialog,
    };
  });
  return (
    <Globe
      regions={regions}
      className={`${className || ""} secondary-bg`}
      css={{ paddingTop: "120px", paddingBottom: "120px" }}
    >
      <DialogContainer>
        <Heading>{heading || "Around the Globe"}</Heading>
        <CtaContainer>
          <CtaText>
            {get(
              carouselItems,
              "[0].title",
              "Want to start an Acumen Fellowship in your country?"
            )}
          </CtaText>
          <CtaButton
            className="main black-button"
            to={`${get(cta, "url", "/contact")}/`}
          >
            {get(cta, "linkText", "Get in Touch")}
          </CtaButton>
        </CtaContainer>
      </DialogContainer>
    </Globe>
  );
};

export const query = graphql`
  fragment FellowshipAroundTheGlobe on ContentfulPageLayout {
    contentModules {
      ... on ContentfulCarouselContent {
        title
        heading
        cta {
          linkText
          url
        }
        carouselItems {
          ... on ContentfulTitleDescription {
            title
            link {
              linkTextAlt
              url
              startDate
              endDate
            }
            description {
              description
            }
          }
        }
      }
    }
  }
`;

export default AroundTheGlobe;
