import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Markdown from "markdown-to-jsx";
import { Link } from "gatsby";
import moment from "moment";

import { theme } from "../../../../../utils/global-styles/theme";

import DownArrow from "../../../../CourseDetail/child-components/CourseSyllabus/assets/DownArrow";

const RegionalAccordion = ({
  region,
  regionDescription,
  startDate,
  endDate,
  linkText,
  linkUrl,
  index,
}) => {
  const [descriptionHeight, setDescriptionHeight] = useState(0),
    currentDate = moment(new Date())
      .format("YYYY-MM-DDTHH:mmZ"),
    applicationStartDate = moment(startDate),
    applicationEndDate = moment(endDate),
    areApplicationsOpen =
      Number(applicationStartDate.diff(currentDate, "days")) <= 0 &&
      Number(applicationEndDate.diff(currentDate, "days")) >= 0;

  const AccordionGrid = styled.div`
      display: grid;
      grid-template-rows: 68px 1fr;
      grid-template-columns: 60% 24% 16%;
      border-bottom: 2px solid ${theme.colors.accent};
      cursor: pointer;
      margin-left: 164px;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        grid-template-columns: 55% 29% 16%;
        margin-left: 110px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template-columns: 50% 34% 16%;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 0px;
        grid-template-columns: 48% 37% 16%;
      }
    `,
    RegionTextContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      display: flex;
      align-items: center;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-area: 1 / 1 / span 1 / span 2;
      }
    `,
    RegionText = styled.h4`
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 30px;
      margin-left: 48px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 33px;
        line-height: 40px;
        margin-left: 13px;
      }
    `,
    ApplicationStatusContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: flex;
      align-items: center;
    `,
    ApplicationStatusText = styled.h3`
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 12px;
        line-height: 24px;
      }
    `,
    ArrowContainer = styled.div`
      grid-area: 1 / 3 / span 1 / span 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 17px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-right: 20px;
      }
    `,
    DescriptionContainer = styled.div`
      grid-area: 2 / 1 / span 1 / span 3;
      overflow-y: hidden;
    `,
    DescriptionText = styled.p`
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.18px;
      line-height: 24px;
      margin: 50px 40px 30px 48px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin: 12px 14px 14px 15px;
      }
    `,
    LearnMoreButton = styled(Link)`
      height: 36px;
      width: 166px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 48px;
      margin-bottom: 50px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 15px;
        margin-bottom: 30px;
      }
    `;

  return (
    <AccordionGrid
      onClick={() => setDescriptionHeight(descriptionHeight === 0 ? "auto" : 0)}
      css={
        index === 0 &&
        css`
          border-top: 2px solid ${theme.colors.accent};
        `
      }
    >
      <RegionTextContainer>
        <RegionText>{region}</RegionText>
      </RegionTextContainer>
      <ApplicationStatusContainer>
        <ApplicationStatusText className="primary">
          {areApplicationsOpen ? "APPLICATIONS OPEN" : ""}
        </ApplicationStatusText>
      </ApplicationStatusContainer>
      <ArrowContainer>
        <DownArrow activeDisplay={descriptionHeight === 0 ? false : true} />
      </ArrowContainer>
      <DescriptionContainer
        css={
          descriptionHeight === 0
            ? css`
                max-height: ${descriptionHeight};
                -webkit-animation: slideUp 1s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
                -moz-animation: slideUp 1s ease-in-out; /* Firefox < 16 */
                -ms-animation: slideUp 1s ease-in-out; /* Internet Explorer */
                -o-animation: slideUp 1s ease-in-out;
                animation: slideUp 1s ease-in-out;
              `
            : css`
                max-height: ${descriptionHeight};
                padding-bottom: 20px;
                -webkit-animation: slideDown 1s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
                -moz-animation: slideDown 1s ease-in-out; /* Firefox < 16 */
                -ms-animation: slideDown 1s ease-in-out; /* Internet Explorer */
                -o-animation: slideDown 1s ease-in-out;
                animation: slideDown 1s ease-in-out;
              `
        }
      >
        {region.toLowerCase().trim() === "west africa" ||
        region.toLowerCase().trim() === "east africa" ? (
          <Markdown
            options={{
              overrides: {
                strong: {
                  props: {
                    className: "fellowship-regional-description-strong",
                  },
                },
                p: {
                  props: {
                    className: "fellowship-regional-description",
                  },
                },
              },
            }}
          >
            {regionDescription}
          </Markdown>
        ) : (
          <DescriptionText>{regionDescription}</DescriptionText>
        )}
        <LearnMoreButton
          className="main primary-bg secondary-background-hover"
          to={`${linkUrl}/`}
        >
          {linkText}
        </LearnMoreButton>
      </DescriptionContainer>
    </AccordionGrid>
  );
};

RegionalAccordion.propTypes = {
  region: PropTypes.string.isRequired,
  regionDescription: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  index: PropTypes.number,
};

export default RegionalAccordion;
